<template>

  <!-- Unique Selling Proposition -->
  <el-col :md="5">
    <div
      v-if="$store.state.idea.storyMode.common.completedSteps.productCompleted || !canEdit"
      class="completed-overlay"
    />
    <!-- Placeholder & Tutorial Tooltip -->
    <div v-if="!proposition" class="placeholder-card small">

      <!-- Placeholder -->
      <div class="w-100">
        <div class="stripe-long" />
        <div class="stripe-short" />
      </div>
      <!-- /Placeholder -->

      <!-- Tutorial Tooltip TODO - Currently disabled in if directive -->
      <tutorial-tooltip
        v-if="$store.state.user.tutorial.uniqueSellingPropositionCompleted === true && 1 === 2"
        :title="'Add a unique selling proposition'"
        :text="'What makes <strong>your product different</strong>? Lorem ipsum dolor sit amet, consectetur, adipiscing elit praesent.'"
        :tutorial-name="'uniqueSellingPropositionCompleted'"
      />
      <!-- /Tutorial Tooltip -->

    </div>
    <!-- /Placeholder & Tutorial Tooltip -->

    <!-- Unique Selling Proposition Block -->
    <div
      v-if="proposition"
      class="proposition-item"
      :class="{'completed-card': $store.state.idea.storyMode.common.completedSteps.productCompleted || !canEdit}"
      @click="$emit('click')"
    >

      <!-- Block Header -->
      <div class="item-header">
        <div class="checkbox" @click="canEdit ? $emit('delete') : null">
          <svg width="10" height="10" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g stroke="#0060EE" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="square"><path d="M8.5 1.5l-7 7M1.5 1.5l7 7"/></g></svg>
        </div>
      </div>
      <!-- /Block Header -->

      <!-- Block Content -->
      <div class="item-content">
        <p>{{ proposition.content }}</p>
      </div>
      <!-- /Block Content -->

    </div>
    <!-- /Unique Selling Proposition Block -->

  </el-col>
  <!-- /Unique Selling Proposition -->

</template>

<script>
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import TutorialTooltip from '@/views/Home/Components/TutorialTooltip'

export default {
  name: 'UniqueSellingProposition',

  components: {
    TutorialTooltip
  },

  mixins: [MixinIdeaRoles],

  props: {
    proposition: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
