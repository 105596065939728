<template>
  <ib-dialog
    id="dialog-story-mode-add-proposition"
    :visible="dialogAddPropositionVisible"
    :fullscreen="true"
    @open="openDialog"
    @close="closeDialog"
  >
    <!-- Content Wrapper -->
    <ib-dialog-container max-width="740px" class="content-wrapper">

      <!-- Header -->
      <header>

        <!-- Title -->
        <h2>{{ $t('pages.businessGuide.product.addUniqueSellingPropositionDialog.title') }}</h2>
        <!-- /Title -->

        <!-- Subtitle -->
        <p>{{ $t('pages.businessGuide.product.addUniqueSellingPropositionDialog.subTitle') }}</p>
        <!-- /Subtitle -->

      </header>
      <!-- /Header -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">

        <!-- Grid Wrapper -->
        <div class="grid-wrapper">
          <el-row :gutter="20">

            <!-- Proposition Items -->
            <el-col v-for="(item, index) in propositionItemsInDialog" :key="index" :md="8">

              <!-- Proposition Item -->
              <div
                class="proposition-item"
                :class="{'selected':item.selected}"
                @click="itemClicked(item)"
              >

                <!-- Item Header -->
                <div class="item-header">
                  <div class="checkbox">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.67 426.67" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M153.504 366.839c-8.657 0-17.323-3.302-23.927-9.911L9.914 237.265c-13.218-13.218-13.218-34.645 0-47.863 13.218-13.218 34.645-13.218 47.863 0l95.727 95.727 215.39-215.386c13.218-13.214 34.65-13.218 47.859 0 13.222 13.218 13.222 34.65 0 47.863L177.436 356.928c-6.609 6.605-15.271 9.911-23.932 9.911z"/></svg>
                  </div>
                </div>
                <!-- /Item Header -->

                <!-- Item Content -->
                <div class="item-content">
                  <p>{{ item.content }}</p>
                </div>
                <!-- /Item Content -->

              </div>
              <!-- /Proposition Item -->

            </el-col>
            <!-- /Proposition Items -->

            <!-- Something Else -->
            <el-col :md="8">
              <div class="add-proposition-item">
                <el-input
                  v-model="somethingElseInput"
                  type="textarea"
                  class="line-input"
                  :placeholder="$t('chooseSomethingElse')"
                  resize="none"
                  maxlength="95"
                  :rows="3"
                  :disabled="selectedCount === MAX_SELECTED_COUNT"
                  @keyup.enter.native="addNewProposition"
                  @blur="addNewProposition"
                />
              </div>
            </el-col>
            <!-- /Something Else -->

          </el-row>
        </div>
        <!-- /Grid Wrapper -->

        <!-- Dialog Bottom -->
        <div class="dialog-bottom">

          <!-- Divider -->
          <hr>
          <!-- /Divider -->

          <!-- Bottom Flex -->
          <div class="bottom-flex justify-content-end">

            <!-- Save -->
            <el-button
              class="small"
              :loading="loading"
              :disabled="loading"
              @click="save"
            >
              {{ $t('save') }}
            </el-button>
            <!-- /Save -->

          </div>
          <!-- /Bottom Flex -->

        </div>
        <!-- /Dialog Bottom -->

      </div>
      <!-- /Inner Wrapper -->

    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
</template>

<script>
export default {
  name: 'DialogAddProposition',

  props: {
    dialogAddPropositionVisible: Boolean
  },

  data () {
    return {
      MAX_SELECTED_COUNT: 3,
      selectedCount: 0,
      loading: false,
      somethingElseInput: '',
      propositionItemsInDialog: [
        {
          id: 1,
          content: this.$t('pages.businessGuide.product.addUniqueSellingPropositionDialog.items.item01'),
          selected: false
        },
        {
          id: 2,
          content: this.$t('pages.businessGuide.product.addUniqueSellingPropositionDialog.items.item02'),
          selected: false
        },
        {
          id: 3,
          content: this.$t('pages.businessGuide.product.addUniqueSellingPropositionDialog.items.item03'),
          selected: false
        },
        {
          id: 4,
          content: this.$t('pages.businessGuide.product.addUniqueSellingPropositionDialog.items.item04'),
          selected: false
        },
        {
          id: 5,
          content: this.$t('pages.businessGuide.product.addUniqueSellingPropositionDialog.items.item05'),
          selected: false
        },
        {
          id: 6,
          content: this.$t('pages.businessGuide.product.addUniqueSellingPropositionDialog.items.item06'),
          selected: false
        },
        {
          id: 7,
          content: this.$t('pages.businessGuide.product.addUniqueSellingPropositionDialog.items.item07'),
          selected: false
        },
        {
          id: 8,
          content: this.$t('pages.businessGuide.product.addUniqueSellingPropositionDialog.items.item08'),
          selected: false
        }
      ]
    }
  },

  methods: {
    openDialog () {
      const propositions = this.$store.state.idea.storyMode.concept.product.uniqueSellingPropositions
      this.selectedCount = 0
      propositions.forEach(prop => {
        const i = this.propositionItemsInDialog.findIndex(p => p.content === prop.content)
        if (i >= 0) {
          this.propositionItemsInDialog[i].selected = true
        } else {
          this.propositionItemsInDialog.push({
            content: prop.content,
            selected: true,
            added: true
          })
        }
        this.selectedCount++
      })
    },

    itemClicked (item) {
      if (!item.selected && this.selectedCount === this.MAX_SELECTED_COUNT) return
      item.selected = !item.selected
      this.selectedCount += item.selected ? 1 : -1
      if (item.added) {
        this.propositionItemsInDialog.splice(this.propositionItemsInDialog.indexOf(item), 1)
      }
    },

    addNewProposition () {
      if (this.somethingElseInput) {
        this.propositionItemsInDialog.push({
          content: this.somethingElseInput,
          selected: true,
          added: true
        })
        this.selectedCount++
        this.somethingElseInput = ''
      }
    },

    save () {
      const selected = this.propositionItemsInDialog.filter(prop => prop.selected)
        .map(prop => ({ content: prop.content }))

      if (this.loading) return

      this.loading = true

      this.$http.post(
        'story-mode/concept/product/unique-selling-proposition/' + this.$store.state.idea.id,
        { uniqueSellingPropositions: selected }
      ).then((response) => {
        this.$store.commit('idea/setUniqueSellingPropositions', selected)
        this.closeDialog()
        this.$emit('save', selected)
      }).finally(() => {
        this.loading = false
      })
    },

    closeDialog () {
      this.somethingElseInput = ''
      this.propositionItemsInDialog = this.propositionItemsInDialog.slice(0, 8)
      this.propositionItemsInDialog.forEach((item) => { item.selected = false })
      this.$emit('discard')
    }
  }
}
</script>
